<template>

  <component :is="jobTitleData === undefined ? 'div' : 'b-card'">
    <!-- Header -->
    <!-- Header -->
    <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
      <h5 class="mb-0">
        Edit Job title
      </h5>
    </div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="jobTitleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching  data
      </h4>
      <div class="alert-body">
        No job title found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-job-titles-list'}"
        >
          Job titles List
        </b-link>
        for other titles.
      </div>
    </b-alert>

    <b-tabs
      v-if="jobTitleData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Job title Details</span>
        </template>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Full Name -->
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="jobTitleData.name"
                  autofocus
                  trim
                  placeholder="Shift Name"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group
              label="Status"
              label-for="user-status"
            >
              <v-select
                v-model="jobTitleData.active"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="shift-status"
              />
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Update
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="$router.push({name: 'apps-job-titles-list'})"
              >
                Cancel
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BFormTimepicker, BModal, VBModal,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTimepicker,
    vSelect,
    BModal,
    VBModal,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
    const toast = useToast()
    const jobTitleData = ref(null)
    store.dispatch('jobTitleStore/fetchJobTitle', { pubId: router.currentRoute.params.id })
      .then(response => { jobTitleData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          jobTitleData.value = undefined
        }
      })

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const blankData = {
      name: '',
      start: '',
      end: '',
    }
    const { end } = jobTitleData
    const { start } = jobTitleData
    const resetData = () => {
      jobTitleData.value = JSON.parse(JSON.stringify(blankData))
    }

    const onSubmit = () => {
      store.dispatch('jobTitleStore/updateJobTitle', jobTitleData.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Information Updated',
              icon: 'AwardIcon',
              variant: 'success',
              solid: true,
            },
          })
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error Updating the data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    const statusOptions = [
      { label: 'Active', value: 1 },
      { label: 'Inactive', value: 2 },
    ]

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      statusOptions,
      jobTitleData,
      start,
      end,
      onSubmit,
      refInputEl,
      previewEl,
      refFormObserver,
      getValidationState,
      resetForm,
      toast,
    }
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
